import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';


//function Images() {
const Images = () => {

    // Utilisation du hook useParams pour obtenir l'id
    const { id } = useParams();

    const goBack = () => {
        localStorage.setItem('from', 'images');
		window.history.back(); 
    };

	const [documentData, setDocumentData] = useState(JSON.parse(localStorage.getItem('documentData')) || null);
    const [isLoading, setIsLoading] = useState(true);  // Montre un loader si les données ne sont pas en cache
	

    useEffect(() => {
		console.log('heyyyyy');
		// Fonction pour recevoir les données depuis Kotlin
		window.getDocument = (data) => {
		  try {
			// Essayer de parse le JSON reçu si c'est une chaîne
			const parsedData = JSON.parse(data); // Assurez-vous de parser les données si elles sont envoyées en tant que chaîne JSON

			// Vérifier si le document est valide
			if (parsedData) {
			  const newData = parsedData; // Utiliser les données directement
			  const cachedData = JSON.parse(localStorage.getItem('documentData')) || {}; // Récupérer les données en cache depuis le localStorage

			  // Comparer les nouvelles données avec celles en cache
			  if (JSON.stringify(newData) !== JSON.stringify(cachedData)) {
				setDocumentData(newData);  // Mettre à jour l'état
				localStorage.setItem('documentData', JSON.stringify(newData));  // Mettre à jour le localStorage
			  }
			  setIsLoading(false); // Arrêter le loader une fois que les données sont récupérées
			} else {
			  console.log('Aucun document trouvé ou erreur de données !');
			  setIsLoading(false);
			}
		  } catch (error) {
			console.error('Erreur lors du traitement des données :', error);
			setIsLoading(false);
		  }
		};

		// Définir la fonction dans l'objet window pour l'appeler depuis Kotlin
		//window.getDocument = getDocument;
		

		// Appeler la fonction Kotlin via l'interface JavaScript
		if (window.Android && typeof window.Android.getDataFromDoc === 'function') {
		  //const docId = id;
		  const docId = 'tiX97OxITUfKgCXA6CgY';
		  console.log("heyyyyy");
		  window.Android.getDataFromDoc(`photos/${docId}`);
		} else {
		  console.error('Interface Android non disponible');
		}

		// Nettoyer l'effet lorsqu'il est démonté
		return () => {
		  window.getDocument = null;  // Supprimer la fonction de l'objet window pour éviter des fuites de mémoire
		};
    }, [id]); // Dépendance vide pour s'exécuter uniquement au montage et démontage
	
	const handleImageLoad = () => {
	  // Une fois que l'image est chargée, vous pouvez vérifier si toutes les ressources sont prêtes
	  setIsLoading(false);
	};

    
    if (isLoading) {
        return <div><><div className="App-header fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="warning" role="status">
            <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
    </div></> </div>;
    }
    /*
    if (error) {
        return <div className='fs-6'>Error: {error}</div>;
    }
    */

    return (
    <div className='App '>
		<nav className="navbar fixed-top">
		  <div className=''>
			<div className="">
			  <Link className='title' onClick={goBack} >
				<i className="fa-solid fa-arrow-left me-2"></i> {documentData.title}
			  </Link>
			</div>
		  </div>
		</nav>
        <br />  <br />
        <div className='container'>
            <br /> 
            <h6 className='fw-bold mt-1 mb-2' style={{ color: '#414141' }}><i className='fa-solid fa-image'></i> Photos/{documentData.title} </h6>
   
            {documentData ? (
            <div>
                {documentData.images.map((item, index) => (
                    <>
                        <div className='mb-3'> 
                            <img key={index} src={item} alt='item' onload={handleImageLoad} className='apps-image' />
                        </div>
                    </>     
                ))}
            </div>
            ) : (
            <div>No data found</div>
            )}
        </div>   
		<Link to='/add-image'><i className="fa fa-camera bg-light floating text-warning p-4 fs-1 me-1"></i></Link>
    </div>
    );
}

export default Images;