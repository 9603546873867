import React, { useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import logo from './../../images/logo.png';


const AddImage = () => {

    const [message, setMessage]    = useState('');
    const [imageURL, setImageURL]  = useState(logo);  // Définir l'état initial à 'logo.png'
	const [loading, setLoading]    = useState(false);
	
	const goBack = () => {
		window.history.back(); 
    };

    const [title, setTitle]        = useState('');
	// Création d'un tableau des données à envoyer
    const formData = [title];

	
	useEffect(() => {
		// Dans le code JavaScript de votre application web
		let imagePath = localStorage.getItem('imagePath');
		// Vérifiez si 'imagePath' n'est pas égal à '0' (ce qui signifie que l'image existe)
		if (imagePath !== '0' && imagePath) {
			setImageURL('file://' + imagePath); // Préfixe avec 'file://' si c'est un chemin local
			localStorage.setItem('imagePath', '0'); // reinitialiser à 0
		} else {
			setImageURL(logo)
			console.log('No image path found in localStorage');
		}
	}, []);  // Ne s'exécute qu'une seule fois lors du premier rendu (comportement similaire à componentDidMount)

	
	/*
	const [showAlert, setShowAlert] = useState(false);
	const toggleAlert = () => {
        setShowAlert(!showAlert);
    };
	*/
	
	const openGallery = () => {
		window.Android.openGallery();
		if (typeof window.Android !== 'undefined' && typeof window.Android.openGallery === 'function') {
        window.Android.openGallery();  // Appel de la fonction Kotlin pour ouvrir la galerie
		//setShowAlert(false); // Fermez l'alerte après un clic
		} else {
			alert("L'interface Android n'est pas disponible.");
		}
    };
	

	const handleSubmit = async (e) => {
		e.preventDefault(); // Empêche le rechargement de la page lors de la soumission
		setLoading(true);    // Active le chargement
		setMessage('');      // Réinitialise le message précédent

		try {
			// Vérifier si l'interface Android est disponible
			if (window.Android && typeof window.Android.addNewDoc === 'function') {
				const collectionPath = 'photos'; // Chemin de la collection Firestore
				
				window.Android.addNewDoc(collectionPath, formData);
				
				setMessage('Album créé avec succès !');
				setTitle(''); // Réinitialise le champ de titre après la soumission
			} else {
				console.error('Interface Android non disponible');
				setMessage('Erreur : Interface Android non disponible');
			}
		} catch (error) {
			// En cas d'erreur, affiche le message d'erreur
			setMessage(`Erreur : ${error.message}`);
		} finally {
			// Désactive le chargement après la soumission
			setLoading(false);
		}
    };
	
	
    return (
        <div className='App container'>
			<nav className="navbar fixed-top">
				<div className=''>
				    <div className="d-flex">
						<Link className='title fw-bolder' onClick={goBack} >
							<i className="fa-solid fa-arrow-left mt-1 me-2"></i> Nouvelle image
						</Link>
				    </div>
				</div>
			</nav>
            <br />  <br />
            <div className='container'>
                <br /> <br />  
                <form onSubmit={handleSubmit}> 
				    <div className='mb-4'>
						<Link onClick={openGallery}>
							<img src={imageURL} alt="default" className='rounded-circle bg-light p-2' width="100px" />
                            <i className='fa-solid fa-camera text-secondary fs-5 ms-1'></i>							
						</Link>
                    </div>
                    <div className='mb-3'>
                        <label>Nom de l'album</label>
                        <input  className='form-control' required value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <button type='submit' disabled={loading} className='btn btn-warning mt-2 btn-lg text-light fw-bold' style={{ width: '100%'}}>
						{loading ? 'Création en cours...' : 'Ajouter'} <i className='fa-solid fa-plus-circle ms-2'></i>
                    </button>
                </form>
            </div>
            <div className='ps-3 pe-3 mt-2'>
            {message && 
                <p className='fs-6 text-success text-center'>{message}</p>
            }
			{/*
			{showAlert && (
				<div className="custom-alert">
				  <button onClick={() => openGallery()}>📄 </button>
				  <button onClick={() => openGallery()}>📷 </button>
				  <button onClick={() => openGallery()}>🎥 </button>
				  <button onClick={() => setShowAlert(false)}>X</button>
				</div>
			)}
			*/}
            </div> 
        </div>
    )
};

export default AddImage;
